import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { breakpoints, colors } from "../../styles/variables";

interface NavLinkProps {
  link: string;
  text: string;
}

const LinkElem = styled(Link)`
  color: white;
  z-index: 5;
  text-decoration: none;
  display: block;
  text-align: center;
  font: 18px / 70px "Oswald Regular";

  .img {
    display: inline-block;
    width: 16.25px;
    height: 26px;
    margin-bottom: -5px;
    margin-right: 9px;
    background-image: url("/assets/gestaltungselemente/location-green-new.svg");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  &:hover {
    color: ${colors.green};
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    font: 28px / 80px "Oswald Regular";
    .img {
      width: 25px;
      height: 40px;
      margin-bottom: -8px;
      margin-right: 20px;
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    font: 32px / 90px "Oswald Regular";

    .img {
      width: 27.5px;
      height: 44px;
      margin-bottom: -9px;
      margin-right: 24px;
    }
  }
`;

const NavLinkLokalerHersteller: React.FC<NavLinkProps> = ({
  text,
  link,
  setNavIsOpen,
}) => (
  <LinkElem to={"/" + link} onClick={() => setNavIsOpen(false)}>
    <div className="img" /> {text}
  </LinkElem>
);

//location-green.svg
export default NavLinkLokalerHersteller;
